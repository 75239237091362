import React, { useState } from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import Layout from "../Layout"
import P from "../components/core/P"
import H2 from "../components/core/H2"
import SEO from "./../components/SEO"
import { chunk } from "./../../src/util/helper"
// import BlogCard from "./../../src/components/core/BlogCard"
import CategoryFilters from "../components/core/CategoryFilters"
const BlogCard = loadable(() => import("./../../src/components/core/BlogCard"))


const BlogPage = ({ data, location }) => {
  const { allWpPost } = data
  //  eslint-disable-next-line no-unused-vars
  const [totalCount, setTotalCount] = useState(allWpPost.nodes.length)
  const [postsToShow, setPostsToShow] = useState(12)

  const handleLoadMore = e => {
    setPostsToShow(postsToShow + 12)
  }
  const posts = allWpPost.nodes.map(e => e)
  return (
    <Layout location={location}>
      <CategoryFilters data={allWpPost.nodes } />
      <SEO
        title={`Top Trending IT Blog | Product Development - WebMob Technologies`}
        desc={`Read top trending IT blogs on the latest technologies and its feasible implementations within the performance-driven web and app product development services. WebMob Technologies.`}
        canonical={`https://webmobtech.com${location.pathname}`}
      />
      <div className="relative bg-transperant pb-20 px-4 sm:px-6 lg:pt-16 pt-16 lg:pb-28 lg:px-8">
        {/* <div className="absolute inset-0">
          <div className="bg-gray-100 h-1/3 sm:h-2/3"></div>
        </div> */}
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <H2
              // className="text-3xl leading-9 tracking-tight font-extrabold text-shark-500 sm:text-4xl sm:leading-10"
              title={`From the Blog`}
            />
            <P
              className="leading-7 text-shark-400"
              title={`Top Trending IT Blogs | Product Development`}
            />
          </div>
          <div className="mt-12 grid gap-10  max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
            {chunk(posts.slice(0, postsToShow), 3).map(e =>
              e.map(f => <BlogCard {...f} key={Math.random()} />)
            )}
          </div>
          {postsToShow <= totalCount && (
            <div className={`mt-24 flex justify-center `}>
              <button
                aria-hidden="true"
                onClick={handleLoadMore}
                className="bg-blue-500 inline-flex relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded"
              >
                See more
              </button>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  {
    allWpPost(sort: { fields: date, order: DESC }) {
      totalCount
      nodes {
        title
        date(formatString: "MMM DD, YYYY")
        uri
        excerpt
        featuredImage {
          node {
            mediaItemUrl
          }
        }
        author {
          node {
            avatar {
              url
            }
            name
            uri
          }
        }
        categories {
          nodes {
            name
            link
          }
        }
      }
    }
  }
`
